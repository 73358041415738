.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Stili generali per tutti i pulsanti */
button {
  padding: 10px 20px;
  background-color: #4CAF50; /* Colore verde moderno */
  color: white;
  border: none;
  border-radius: 25px; /* Arrotondati */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease; /* Transizione per hover */
  outline: none;
}

button:hover {
  background-color: #45a049; /* Cambia il colore quando l'utente passa sopra */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Aumenta l'ombra */
  transform: translateY(-2px); /* Solleva il pulsante */
}

button:active {
  background-color: #3e8e41; /* Colore più scuro quando viene premuto */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* Ombra ridotta */
  transform: translateY(1px); /* Effetto pressione */
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.5); /* Contorno visibile per accessibilità */
}

/* Stili specifici per i pulsanti principali */
button.primary {
  background-color: #2196F3; /* Blu */
}

button.primary:hover {
  background-color: #1e88e5; /* Hover più scuro */
}

/* Stili per i pulsanti di azioni pericolose */
button.danger {
  background-color: #f44336; /* Rosso */
}

button.danger:hover {
  background-color: #e53935; /* Hover più scuro */
}

/* Stili generali per le textbox (input e textarea) */

input, textarea {
  width: 100%;
  max-width: 400px; /* Larghezza massima per evitare che diventino troppo lunghe */
  padding: 12px 15px;
  margin: 8px auto; /* Centrato orizzontalmente */
  display: block; /* Display block per il margin auto */
  border: 1px solid #ccc;
  border-radius: 25px; /* Bordi arrotondati */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
  font-size: 16px;
  background-color: #f9f9f9; /* Colore di sfondo leggermente grigio */
  transition: all 0.3s ease;
  outline: none; /* Rimuove il bordo di default */
}

/* Stile per il focus */
input:focus, textarea:focus {
  border-color: #4CAF50; /* Cambia il colore del bordo quando si clicca dentro */
  background-color: #fff; /* Sfondo bianco al focus */
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5); /* Ombra verde leggera al focus */
}

/* Stile per l'hover */
input:hover, textarea:hover {
  border-color: #aaa; /* Colore del bordo più scuro all'hover */
}

/* Stile per l'input disabilitato */
input:disabled, textarea:disabled {
  background-color: #e0e0e0; /* Colore grigio chiaro per input disabilitato */
  cursor: not-allowed;
}

.button-column {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra i pulsanti orizzontalmente */
  gap: 10px; /* Aggiunge spazio tra i pulsanti */
}


.weight-difference h4 {
  margin-bottom: 10px;
}

.difference-box {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50%; /* Box rotondo */
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}

.difference-box.positive {
  background-color: #4CAF50; /* Verde per valore positivo */
}

.difference-box.negative {
  background-color: #F44336; /* Rosso per valore negativo */
}
/* Aggiunta delle Media Queries per la reattività */



.zoom-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.zoom-control, .zoom-arrow {
  flex: 1;
  max-width: 60px;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-arrow {
  max-width: 50px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .zoom-buttons {
    justify-content: space-between;
  }

  .zoom-control {
    max-width: 80px;
    font-size: 14px;
  }

  .zoom-arrow {
    max-width: 40px;
  }
}




/* Stili per schermi piccoli come telefoni e tablet */
@media (max-width: 768px) {

  
  .App {
    padding: 10px;
  }

  h1, h2, h3, h4 {
    font-size: 1.5em; /* Riduci la dimensione del testo */
  }

  /* Contenitore per i grafici */
  .grafici-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spazio uniforme tra i grafici */
    justify-content: space-between;
  }


  .grafico {
    width: 100%; /* Prendi tutta la larghezza dello schermo */
    height: 400px; /*auto; /* Mantieni l'altezza proporzionale */
    margin-bottom: 10px; /* Spazio tra i grafici */
  }

    /* Specifico per il grafico lineare */
    .grafico-temperatura,  .grafico-thi {
      width: 100%;
      height: 250px;
      margin-bottom: 100px;
    }
        /* Specifico per il grafico lineare */
         .grafico-peso {
          width: 100%;
          height: 250px;
          margin-bottom: 150px;
        }

    /* Grafici a ciambella */
    .grafico-ciambella {
      width: calc(50% - 10px); /* Ogni grafico a ciambella occupa metà della larghezza meno margine */
      height: 200px;
      margin-bottom: 20px;
    }

  /* Mappa e box altitudine */
  .mappa-container {
    flex-direction: column;
  }

  .mappa {
    width: 100%;
    height: 200px; /* Dimensione più piccola della mappa */
  }

  .altitudine-box {
    width: 100%;
    margin-top: 10px;
    text-align: center;
  }

  /* Textbox e pulsanti */
  input, button {
    width: 100%;
    margin-bottom: 10px;
  }

  /* Disposizione orizzontale per le textbox e i pulsanti */
.finestra-filtri-row {
  display: flex;
  align-items: center; /* Allinea verticalmente i pulsanti e le textbox */
  justify-content: space-between;
  margin-bottom: 10px; /* Spazio tra le righe */
  flex-wrap: wrap; /* Aggiunto per consentire la disposizione su più righe in caso di overflow */
}

.finestra-filtri-row input {
  flex-basis: 70%; /* La textbox prende il 70% dello spazio */
  margin-right: 100px; /* Spazio tra la textbox e il pulsante */
}

.finestra-filtri-row button {
  flex-basis: 25%; /* Il pulsante prende il 25% dello spazio */
  padding: 8px 15px;
  width: auto; /* Mantieni la larghezza automatica per il pulsante */
}

/* Gestisci i casi di schermo molto stretto */
@media (max-width: 600px) {
  .finestra-filtri-row input, 
  .finestra-filtri-row button {
    flex-basis: 100%; /* Su schermi molto piccoli, imposta il 100% per entrambi */
    margin-bottom: 10px; /* Aggiungi spazio tra le textbox e i pulsanti */
  }
}

}

/* Stili per schermi più grandi (tablet e desktop) */
@media (min-width: 769px) {
  .App {
    padding: 20px;
  }

  .grafici-container {
    display: flex;
    justify-content: space-between;
  }

  .grafico {
    width: 100%; /* Prendi tutta la larghezza dello schermo */
    height: 400px; /*auto; /* Mantieni l'altezza proporzionale */
    margin-bottom: 20px; /* Spazio tra i grafici */
  }

    /* Specifico per il grafico della temperatura */
    .grafico-temperatura {
      width: 100%; /* Larghezza piena */
      height: 300px; /* Altezza fissa per garantire visibilità */
      margin-bottom: 100px;
    }

    .grafico-peso {
      width: 100%; /* Imposta la larghezza del grafico a 100% */
      height: 300px; /* Imposta l'altezza del grafico */
      margin-bottom: 150px;
    }

    .grafico-thi {
      width: 100%; /* Imposta la larghezza del grafico a 100% */
      height: 300px; /* Imposta l'altezza del grafico */
      margin-bottom: 100px;
    }

  .mappa-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mappa {
    margin-top: 20px;
    width: 40%;
    height: 200px;
  }

  .altitudine-box {
    background-color: #87CEEB; /* Celeste */
    color: #333; /* Colore del testo */
    padding: 15px; /* Spaziatura interna */
    border-radius: 10px; /* Bordi arrotondati */
    font-size: 20px; /* Dimensione del testo */
    font-weight: bold; /* Testo in grassetto */
    text-align: center; /* Testo centrato */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Aggiunta di un'ombra leggera */
    margin-left: 20px;
    margin-right: 300px; /* Distanza tra la mappa e il box altitudine */
    margin-top: 10px; /* Elimina il margine superiore per avvicinare il box alla mappa */
  }

  .date-picker-input {
    width: 100%; /* Imposta la larghezza desiderata */
    max-width: 100px; /* Limita la larghezza massima */
    margin-right: 20px; /* Aggiunge uno spazio a destra */
  }

}
/* Stili per schermi più grandi (tablet e desktop) */
@media (min-width: 850px) {
  .App {
    padding: 20px;
  }

  .grafici-container {
    display: flex;
    justify-content: space-between;
  }

  .grafico {
    width: 100%; /* Prendi tutta la larghezza dello schermo */
    height: 400px; /*auto; /* Mantieni l'altezza proporzionale */
    margin-bottom: 20px; /* Spazio tra i grafici */
  }

    /* Specifico per il grafico della temperatura */
    .grafico-temperatura {
      width: 100%; /* Larghezza piena */
      height: 400px; /* Altezza fissa per garantire visibilità */
      margin-bottom: 100px;
    }

    .grafico-peso {
      width: 100%; /* Imposta la larghezza del grafico a 100% */
      height: 450px; /* Imposta l'altezza del grafico */
      margin-bottom: 150px;
    }

    .grafico-thi {
      width: 100%; /* Imposta la larghezza del grafico a 100% */
      height: 400px; /* Imposta l'altezza del grafico */
      margin-bottom: 100px;
    }

  .mappa-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mappa {
    margin-top: 20px;
    width: 40%;
    height: 200px;
  }

  .altitudine-box {
    background-color: #87CEEB; /* Celeste */
    color: #333; /* Colore del testo */
    padding: 15px; /* Spaziatura interna */
    border-radius: 10px; /* Bordi arrotondati */
    font-size: 20px; /* Dimensione del testo */
    font-weight: bold; /* Testo in grassetto */
    text-align: center; /* Testo centrato */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Aggiunta di un'ombra leggera */
    margin-left: 20px;
    margin-right: 300px; /* Distanza tra la mappa e il box altitudine */
    margin-top: 10px; /* Elimina il margine superiore per avvicinare il box alla mappa */
  }

  .date-picker-input {
    width: 100%; /* Imposta la larghezza desiderata */
    max-width: 100px; /* Limita la larghezza massima */
    margin-right: 20px; /* Aggiunge uno spazio a destra */
  }

}
